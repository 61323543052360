'use client';

import { useEffect } from 'react';

import { useParams } from 'next/navigation';

import { Lang } from '@app/_internal/i18n/settings';
import I18nextProvider from '@app/_internal/providers/i18n-provider';
import { UiProvider } from '@app/_internal/providers/ui-provider';
import { AppErrorPage } from '@/initializers/AppErrorBoundary';

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};

const Error = ({ error, reset }: Props) => {
  const params = useParams<{ lang: Lang }>();

  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <UiProvider>
      <I18nextProvider lang={params?.lang!}>
        <AppErrorPage error={error} reset={reset} />
      </I18nextProvider>
    </UiProvider>
  );
};

export default Error;
